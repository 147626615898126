<ng-container *ngFor="let item of menuBarItems; first as first">
  <ng-container *ngxPermissionsOnly="item.permission">
    <a
      class="menu-bar--item"
      *ngIf="!item.href && !item.dropdown"
      (click)="onNavigate(item)"
    >
      <ng-container
        *ngTemplateOutlet="iconCard; context: { $implicit: item }"
      ></ng-container>
    </a>

    <div
      class="menu-bar--item p-0"
      (mouseleave)="p.close()"
      (click)="toggleDropDown(item.id)"
      *ngIf="!item.href && item.dropdown"
      [class.active]="item.dropdown.isOpen"
    >
      <a
        class="menu-bar-dropdown"
        placement="right-top"
        [ngbPopover]="!isMobileMenu && !menuIsOpen ? popoverDropdown : ''"
        #p="ngbPopover"
        (mouseenter)="p.open()"
      >
        <ng-container
          *ngTemplateOutlet="iconCard; context: { $implicit: item }"
        ></ng-container>

        <ng-template #popoverDropdown let-params="popoverData">
          <div *ngFor="let data of item.dropdown.types">
            <a *ngIf="data.href" [target]="data.target" [href]="data.href">{{
              data.name
            }}</a>
            <a
              *ngIf="data.link"
              [target]="data.target"
              (click)="onNavigate(data)"
              >{{ data.name }}</a
            >
          </div>
        </ng-template>
      </a>
    </div>

    <ng-container> </ng-container>

    <a
      class="menu-bar--item"
      *ngIf="item.href"
      [href]="item.href"
      [target]="item.target"
      [ngbTooltip]="!isMobileMenu && !menuIsOpen ? item.name : ''"
      placement="right"
    >
      <ng-container
        *ngTemplateOutlet="iconCard; context: { $implicit: item }"
      ></ng-container>
    </a>

    <ng-template #iconCard let-item>
      <span
        *ngIf="first && isMobileMenu; else menuIcon"
        (click)="onCloseMobileMenu()"
        inlineSVG="/assets/svg/close.svg"
      ></span>

      <ng-template #menuIcon>
        <img
          [src]="item.icon"
          [class.navigation-link]="item.href"
          (click)="first && onToggleMenu()"
        />
      </ng-template>

      <ng-container *ngIf="menuIsOpen || isMobileMenu">
        <span
          *ngIf="!first"
          class="menu-bar--item-title"
          [class.ml-2]="item.dropdown"
          >{{ item.name | uppercase }}</span
        >
        <img
          *ngIf="first"
          src="/assets/img/fgr-logo.png"
          class="fgr-logo"
          alt="fgr logo"
          [class.ml-2]="item.dropdown"
        />
        <span
          *ngIf="item.dropdown"
          inlineSVG="/assets/svg/arrow-down.svg"
          class="arrow-icon"
          [class.arrow-active]="item.dropdown.isOpen"
        ></span>
      </ng-container>
    </ng-template>
    <ng-container
      *ngIf="
        (menuIsOpen || isMobileMenu) && item.dropdown && item.dropdown.isOpen
      "
    >
      <ng-container *ngFor="let type of item.dropdown.types">
        <div
          *ngIf="type.link"
          class="menu-bar--dropdown-title"
          (click)="onNavigate(type)"
        >
          {{ type.name | uppercase }}
        </div>

        <a
          *ngIf="type.href"
          class="menu-bar--dropdown-title d-block"
          [href]="type.href"
          [target]="type.target"
        >
          {{ type.name | uppercase }}
        </a>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
