import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbDatepickerModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '@tenant/core';
import { DirectivesModule } from '@tenant/directives';
import { PipesModule } from '@tenant/ot';
import { TextMaskModule } from 'angular2-text-mask';
import { ClickOutsideModule } from 'ng-click-outside';
import { InlineSVGModule } from 'ng-inline-svg';
import { QuillModule, QUILL_CONFIG_TOKEN } from 'ngx-quill';
import { NgSelectModule } from '@ng-select/ng-select';

import { AddressModalComponent } from './address-modal/address-modal.component';
import { AddressComponent } from './address/address.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { CheckboxGroupDirective } from './checkbox-group/checkbox-group.directive';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { Checkbox2Component } from './checkbox2/checkbox2.component';
import { CommissionCurrencyComponent } from './commission-currency/commission-currency.component';
import { CommissionCurrencyDirective } from './commission-currency/commission-currency.directive';
import { DateRangeComponent } from './date-range/date-range.component';
import { DateComponent } from './date/date.component';
import { DateDirective } from './date/date.directive';
import { componentMapping } from './dynamic-form/component-mapping';
import { componentMappingToken } from './dynamic-form/component-mapping.token';
import { ConditionService } from './dynamic-form/condition.service';
import { DynamicAddressComponent } from './dynamic-form/dynamic-address/dynamic-address.component';
import { DynamicCheckboxComponent } from './dynamic-form/dynamic-checkbox/dynamic-checkbox.component';
import { DynamicCommissionCurrencyComponent } from './dynamic-form/dynamic-commission-currency/dynamic-commission-currency.component';
import { DynamicConditionDirective } from './dynamic-form/dynamic-condition.directive';
import { DynamicCurrencyComponent } from './dynamic-form/dynamic-currency/dynamic-currency.component';
import { DynamicDateComponent } from './dynamic-form/dynamic-date/dynamic-date.component';
import { DynamicDropdownComponent } from './dynamic-form/dynamic-dropdown/dynamic-dropdown.component';
import { DynamicFieldDirective } from './dynamic-form/dynamic-field.directive';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFormService } from './dynamic-form/dynamic-form.service';
import { DynamicGrossComponent } from './dynamic-form/dynamic-gross/dynamic-gross.component';
import { DynamicGroupComponent } from './dynamic-form/dynamic-group/dynamic-group.component';
import { DynamicInputComponent } from './dynamic-form/dynamic-input/dynamic-input.component';
import { DynamicPercentComponent } from './dynamic-form/dynamic-percent/dynamic-percent.component';
import { DynamicReferenceComponent } from './dynamic-form/dynamic-reference/dynamic-reference.component';
import { FileDndComponent } from './file-dnd/file-dnd.component';
import { FormContainerComponent } from './form-container/form-container.component';
import { InlineEditComponent } from './inline-edit/inline-edit.component';
import { CustomDateFormatter } from './input/custom-date-formatter';
import { InputComponent } from './input/input.component';
import { OtFormErrorDirective } from './ot-form-error.directive';
import { OtFormHintDirective } from './ot-form-hint.directive';
import { OtInputDirective } from './ot-input.directive';
import { OtLabelDirective, OtLabelRightDirective } from './ot-label.directive';
import { PasswordStrengthBarComponent } from './password-strength-bar/password-strength-bar.component';
import { OtRadioRegistryService } from './radio/ot-radio-registry.service';
import { RadioControlDirective } from './radio/radio-control.directive';
import { RadioComponent } from './radio/radio.component';
import { OtSelectBoxComponent } from './select-box/select-box.component';
import { SelectItemComponent } from './select-box/select-item/select-item.component';
import { SelectDirective } from './select-box/select.directive';
import { SelectComponent } from './select/select.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { TimepickerDirective } from './timepicker/timepicker.directive';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { DynamicHeaderComponent } from './dynamic-form/dynamic-header/dynamic-header.component';
import { DynamicQuestionsComponent } from './dynamic-form/dynamic-questions/dynamic-questions.component';
import { MultiselectComponent } from './multiselect/multiselect.component';

const ENTRY_COMPONENTS = [
  DynamicInputComponent,
  DynamicAddressComponent,
  DynamicDropdownComponent,
  DynamicGroupComponent,
  DynamicCurrencyComponent,
  DynamicCommissionCurrencyComponent,
  DynamicCheckboxComponent,
  DynamicGrossComponent,
  DynamicDateComponent,
  DynamicPercentComponent,
  DynamicReferenceComponent,
  AddressModalComponent,
  DynamicHeaderComponent,
  DynamicQuestionsComponent,
];

const DIRECTIVES = [
  DynamicConditionDirective,
  DateDirective,
  OtLabelDirective,
  OtFormErrorDirective,
  OtFormHintDirective,
  OtInputDirective,
  DynamicFieldDirective,
  OtLabelRightDirective,
  CheckboxGroupDirective,
  SelectDirective,
  CommissionCurrencyDirective,
  RadioControlDirective,
];

const COMPONENTS = [
  InputComponent,
  SelectComponent,
  CheckboxComponent,
  TypeaheadComponent,
  DateRangeComponent,
  FileDndComponent,

  AddressComponent,
  FormContainerComponent,
  OtSelectBoxComponent,
  CheckboxGroupComponent,
  Checkbox2Component,
  InlineEditComponent,
  DynamicFormComponent,
  PasswordStrengthBarComponent,
  DateComponent,
  DateDirective,
  TimepickerComponent,
  TimepickerDirective,
  SelectItemComponent,
  CommissionCurrencyComponent,
  RadioComponent,
  MultiselectComponent,
  ...ENTRY_COMPONENTS,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    TextMaskModule,
    NgbModule,
    QuillModule.forRoot(),
    // SelectModule,
    DirectivesModule,
    ClickOutsideModule,
    PipesModule,
    // OtModule,
    CoreModule,
    NgbDatepickerModule,
    NgSelectModule,
  ],
  declarations: [...COMPONENTS, ...DIRECTIVES],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    NgbDatepickerModule,
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  providers: [
    OtRadioRegistryService,
    ConditionService,
    DynamicFormService,
    {
      provide: NgbDateParserFormatter,
      useFactory: DateParserFormatterFactory,
    },
    {
      provide: NgbDatepickerConfig,
      useFactory: NgbDatepickerConfigFactory,
    },
    {
      provide: componentMappingToken,
      useFactory: componentMapping,
    },
  ],
})
export class OtFormsModule {}

export function DateParserFormatterFactory() {
  return new CustomDateFormatter('MM/DD/YYYY');
}
export function NgbDatepickerConfigFactory() {
  const conf = new NgbDatepickerConfig();
  conf.firstDayOfWeek = 0;
  return conf;
}
